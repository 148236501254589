<template>
  <div>
    <category/>
  </div>
</template>
<script>
import category from './category.vue'
export default {
  name: 'categoryIndex',
  components:{category},
  data() {
    return {
        iscur:0,
    }
  },
  methods:{

  }
};
</script>
<style scoped lang="scss">
.classify {
  overflow: hidden;
  top: 44px;
  bottom: 0;
  z-index: 99;
  background: #fff;
  li {
    height: 43px;
    width: 100%;
    line-height: 43px;
    font-size: 13px;
    text-align: center;
    overflow: hidden;
  }
}
.iscur {
    font-size: 14px !important;
    background: #e62828;
    color: #fff;
  }
.primary-cla {
  width: 26%;
  height: 100%;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
  float: left;
  overflow: scroll;
  li {
    height: 43px;
    width: 100%;
    line-height: 43px;
    font-size: 13px;
    text-align: center;
    overflow: hidden;
  }
}

.cla-box {
}
.z-clearfix {
  zoom: 1;
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
</style>