<template>
  <div style="background: #f7f7f7">
    <!-- <van-nav-bar title="kategori" left-arrow @click-left="onClickLeft">
      <template #left>
        <van-icon name="arrow-left" size="20" />
      </template>
    </van-nav-bar> -->
    <nav-bar show-back="1">
      <div class="nav-title"> KATEGORI</div>
    </nav-bar>
    <div class="category">
      <div class="category-title">
        <van-row>
          <van-col span="1"></van-col>
          <van-col span="8" class="title">
            <span> Semua Produk</span>
          </van-col>
          <van-col span="12"></van-col>
          <van-col span="3"><van-icon name="search" size="24" /></van-col>
        </van-row>
      </div>
      <div class="category-classify">
        <van-row >
          <van-col span="1"></van-col>
          <van-col span="6" @click="isshow" class="all">
            <span class="st-icon-pandora" style="margin-right: 5px">Semua</span>
            <van-icon :name="ishsow ? arrowup : arrowdown" />
          </van-col>
          <van-col span="4" class="all">
            <span style="margin-right: 5px">Harga</span>
             <van-icon :name="ishsow ? arrowup : arrowdown" />
          </van-col>
           <van-col span="1"></van-col>
          <van-col span="6" class="all">
             <span style="margin-right: 5px">Panas</span>   
            <van-icon :name="ishsow ? arrowup : arrowdown" />
             </van-col>
        </van-row>
      </div>
      <div class="classify" v-show="isshowclassify">
        <ul class="cla_ul primary-cla J_primary_class">
          <li
            :class="{ iscur: iscur == index }"
            v-for="(item, index) in list"
            :key="index"
            @click="classifyto(index, item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="goods-list fx-row " v-if="goods">
      <div style="width:50%"> 
        <div v-for="(item, index) of goodsleft" :key="index">
          <good-item
            :info="item"
            :isitem="isitem"
            :isActive="isActive"
          ></good-item>
          <div v-if="goodsleft==0">暂无商品</div>
        </div>
      </div>
      <div style="width:50%">
        <good-item
          v-for="(item, index) of goodright"
          :isitem="isitem"
          :isActive="isActive"
          :key="index"
          :info="item"
        ></good-item>
      </div>
    </div>
    <bottom isactive="2"></bottom>
  </div>
</template>
<script>
import { filter } from "ramda";
import Bottom from "../../components/bottom";
import service from "../../service";
import GoodItem from "../../components/goodsitem";
import NavBar from "../../components/navBar";
export default {
  components: { Bottom, GoodItem,NavBar },
  data() {
    return {
      loading: false,
      ishsow: false,
      isActive: true,
      isitem: false,
      isshowclassify: false,
      arrowup: "arrow-up",
      arrowdown: "arrow-down",
      iscur: 1,
      leftclassify: [],
      dailyGood: false,
      goods: [],
      tab: 0,
      tabs: false,
      goodsleft: [],
      goodright: [],
      list:[
        {
        title:'Pakaian Pria', //男装
        id:11
      },
       {
        title:'Sepatu Pria',
        id:12
      },
      {
        title:'Elektronik',
        id:13
      },
      {
        title:'Komputer & Aksesoris',
        id:14
      },
      {
        title:'Tas Pria',
        id:16
      },
      {
        title:'Aksesoris Fashion',
        id:17
      },
      {
        title:'Perawatan & Kecantikan',
        id:22
      },
       {
        title:'Pakaian Wanita',
        id:24
      },
      {
        title:'Fashion Muslim',
        id:25
      },
      {
        title:'Fashion Bayi & Anak',
        id:26
      },
       {
        title:'Sepatu Wanita',
        id:28
      },
      {
        title:'Tas Wanita',
        id:29
      },
      {
        title:'Olahraga & Outdoor',
        id:31
      },
     ],
      categoryidlist: [
        {
          title: "panas",
          id: 38,
        },
        // {
        //   title: "Perhatian Kecantikan",
        //   id: 22,
        // },
        // {
        //   title: "Elektronik",
        //   id: 13,
        // },
        {
          title: "Aksesoris Fashion",
          id: 17,
        },
        {
          title: "Sumber Hari",
          id: 37,
        },
        // {
        //   title: "Ibu & Bayi",
        //   id: 27,
        // },
        {
          title: "Fashion Bayi & Anak",
          id: 26,
        },
        {
          title: "Fashion Muslim",
          id: 25,
        },
        {
          title: "Komputer & Akses",
          id: 14,
        },
        {
          title: "Pakaian Wanita",
          id: 24,
        },
        {
          title: "Bag Wanita",
          id: 29,
        },
        {
          title: "Sepatu Wanita",
          id: 28,
        },
        {
          title: "Sports&Outdoor",
          id: 31,
        },
        {
          title: "Makanan & Minum",
          id: 21,
        },
        {
          title: "Manusia Memakai",
          id: 11,
        },
        {
          title: "Sepatu Manusia",
          id: 12,
        },
        {
          title: "Men Bag",
          id: 16,
        },
        {
          title: "Ponsel & Akses",
          id: 15,
        },
        // {
        //   title: "Lihat",
        //   id: 18,
        // },
        // {
        //   title: "Kesehatan",
        //   id: 19,
        // },
        {
          title: "Hobbies&Koleksi",
          id: 20,
        },
        {
          title: "Buku & Stationery",
          id: 32,
        },
        {
          title: "Voucher",
          id: 33,
        },
        {
          title: "Souvenirs&Parties",
          id: 34,
        },
        // {
        //   title: "Fotografi",
        //   id: 35,
        // },
        {
          title: "Bill Telepon",
          id: 36,
        },
      ],
    };
  },
  computed: {
    // goodslefts() {
    //   return (this.goodsleft = this.goods.filter((index) => index % 2 === 0));
    // },
    // goodrights() {
    //   return (this.goodright = this.goods.filter((index) => index % 2 === 1));
    // },
  },
  created(){
  }  ,
  mounted() {
    this.getCategories();
    this.getGoods();
  },
  methods: {
    init() {
      this.getCategories()
        .then(this.getGoods)
        .then(this.$hideLoading)
        .then(() => {
          //滚到指定位置
          if (this.$route.query.anchor) {
            this.$nextTick(this.gotoAnchor);
          }
        });
    },
    gotoAnchor() {
      let id = "goods";
      let anchor = document.getElementById(id);
      if (anchor) {
        let scrollTop = anchor.offsetTop;
        document.body.scrollTop = scrollTop;
        document.documentElement.scrollTop = scrollTop;
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    classifyto(index, id) {
      if(this.iscur==index){
       return
      }else{
        this.iscur=index
      }
       this.$showLoading()
      service.getGoods({ category: id }).then((res) => { 
        this.goods = res.activities;
      this.goodsleft = res.activities.filter((item, index) => {
        if (index % 2 == 0) return this.goodsleft.push(item);
      });
      this.goodright = res.activities.filter((item, index) => {
        if (index % 2 == 1) return this.goodright.push(item);
      });
        this.$hideLoading()
      });
    },
    isshow() {
      this.ishsow = !this.ishsow;
      this.isshowclassify = !this.isshowclassify;
      this.isitem = !this.isitem;
      this.isActive = !this.isActive;
    },
    getCategories() {
      service
        .getCategories({
        })
        .then((data) => {
          return (
            (data &&
              filter((item) => {
                // 排除非实物和非话费商品
                return item.id !== 3 || item.id !== 4;
              })(data)) ||
            []
          );
        })
        .catch(() => {
          return [];
        })
        .then((tabs) => {
          let arr1 = tabs.slice(35, 36); //*
          let arr2 = tabs.slice(19, 20); //*
          let arr3 = tabs.slice(10, 11); //*
          let arr4 = tabs.slice(14, 15); //*
          let arr5 = tabs.slice(34, 35); //*
          let arr6 = tabs.slice(24, 25); //*
          let arr7 = tabs.slice(23, 24); //*
          let arr8 = tabs.slice(22, 23); //*
          let arr9 = tabs.slice(11, 12); //*
          let arr10 = tabs.slice(21, 22); //*
          let arr11 = tabs.slice(26, 27); //*
          let arr12 = tabs.slice(25, 26); //*
          let arr13 = tabs.slice(28, 29); //*
          let arr14 = tabs.slice(18, 19); //*
          let arr15 = tabs.slice(20, 21); //*
          let arr16 = tabs.slice(8, 9); //** */
          let arr17 = tabs.slice(9, 10); //*
          let arr18 = tabs.slice(13, 14); //** */
          let arr19 = tabs.slice(12, 13); //** */
          let arr20 = tabs.slice(15, 16); //** */
          let arr21 = tabs.slice(16, 17); //** */
          let arr22 = tabs.slice(17, 18); //*
          let arr23 = tabs.slice(29, 30); //*
          let arr24 = tabs.slice(27, 28); //*
          let arr25 = tabs.slice(30, 31); //*
          let arr26 = tabs.slice(31, 32); //*
          let arr27 = tabs.slice(32, 33); //*
          let arr28 = tabs.slice(33, 34); //*
          var arr = [];
          let tabarr = arr.concat(
            arr1,
            arr2,
            arr3,
            arr4,
            arr5,
            arr6,
            arr7,
            arr8,
            arr9,
            arr10,
            arr11,
            arr12,
            arr13,
            arr14,
            arr15,
            arr16,
            arr17,
            arr18,
            arr19,
            arr20,
            arr21,
            arr22,
            arr23,
            arr24,
            arr25,
            arr26,
            arr27,
            arr28
          );
          tabarr.forEach((it) => {
            it.name = it.id === 38 ? "panas" : it.name;
            it.name = it.id === 22 ? "Perhatian Kecantikan" : it.name;
            it.name = it.id === 13 ? "Elektronik" : it.name;
            it.name = it.id === 17 ? "Aksesoris Fashion" : it.name;
            it.name = it.id === 37 ? "Sumber Hari" : it.name;
            it.name = it.id === 27 ? "Ibu & Bayi" : it.name;
            it.name = it.id === 26 ? "Fashion Bayi & Anak" : it.name; //*
            it.name = it.id === 25 ? "Fashion Muslim" : it.name; //** */
            it.name = it.id === 14 ? "Komputer & Akses" : it.name; //*
            it.name = it.id === 24 ? "Pakaian Wanita" : it.name; //*
            it.name = it.id === 29 ? "Bag Wanita" : it.name; //*
            it.name = it.id === 28 ? "Sepatu Wanita" : it.name; //*
            it.name = it.id === 31 ? "Sports&Outdoor" : it.name; //*
            it.name = it.id === 21 ? "Makanan & Minum" : it.name; //*
            it.name = it.id === 11 ? "Manusia Memakai" : it.name; //*
            it.name = it.id === 12 ? "Sepatu Manusia" : it.name; //*
            it.name = it.id === 16 ? "Men Bag" : it.name; //*
            it.name = it.id === 15 ? "Ponsel & Akses" : it.name; //*
            it.name = it.id === 18 ? "Lihat" : it.name; //*
            it.name = it.id === 19 ? "Kesehatan" : it.name; //*
            it.name = it.id === 20 ? "Hobbies&Koleksi" : it.name; //*
            it.name = it.id === 32 ? "Buku & Stationery" : it.name; //*
            it.name = it.id === 33 ? "Vouchers" : it.name; //*
            it.name = it.id === 34 ? "Souvenirs&Parties" : it.name; //*
            it.name = it.id === 35 ? "Fotografi" : it.name; //*
            it.name = it.id === 36 ? "Bill Telepon" : it.name; //*
          });
          this.leftclassify = tabarr;
        });
    },
    getGoods() {
        this.$showLoading()
      return service
        .getGoods({category:26})
        .then((data) => {
          this.loading = true;
          this.goods = data.activities;
          this.$hideLoading()
          this.goods.forEach((item, index) => {
            let ood = index % 2 == 0;
            //是否偶数
            if (!ood) {
              this.goodright.push(item);
            } else {
              this.goodsleft.push(item);
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.newUserGood = false;
          this.dailyGood = false;
          this.goods = [];
        });
    },
  },
  watch: {
    $route() {
      //滚到指定位置
      if (
        this.$route.path.indexOf("groups/category") >= 0 &&
        this.$route.query.anchor
      ) {
        this.$nextTick(this.gotoAnchor);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.van-icon{
  vertical-align: middle;
}
.category {
  width: 100%;
  height: 100%;
  background: #fff;
  border-top: 1px solid #323233;
  .category-title {
    width: 100%;
    height: pxTo(50);
    line-height: 50px;
    .title {
      text-align: center;
      line-height: 50px;
      font-size: 17px;
      font-weight: 600;
      color: #323233;
    }
    .van-icon-search {
      color: #323233;
      vertical-align: middle;
    }
  }
  .category-head {
    width: 100%;
    height: pxTo(40);
  }
}
.all {
  color: #323233;
  font-size: 14px;
  line-height: 20px;
   margin-bottom: 10px;
}
.category-classify {
  margin-bottom: 15px;
  background: #fff;
  .tag {
    width: 100%;
    margin-bottom: 10px;
  }
}
.classify {
  // overflow: hidden;
  // top: 44px;
  // bottom: 0;
  // z-index: 99;
  width: 100%;
  // background: #fff;
  // overflow-y: scroll;
   // margin-bottom: 60px;
}
.iscur {
  font-size: 13px !important;
  background: #fff;
  color: rgb(255, 80, 0);
  font-weight: bold;
  border-right: 1px solid rgb(255, 80, 0);
  box-sizing: border-box;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
.primary-cla {
  width: 27%;
  height: pxTo(600);
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  //border-right: 1px solid #323233;
  float: left;
  color: #323233;
  overflow: scroll;
  margin-bottom: 50px;
  //  word-wrap: break-word;
  //  word-break: break-all;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 45px;
    width: 100%;
    // line-height: 43px;
    font-size: 13px;
    text-align: center;
    overflow: hidden;
    word-wrap: break-all;
    word-break: normal;
    //border-top: 1px solid #323233;
    // &:first-child{
    //   border-top: 1px solid #323233
    // }
    &:last-child {
      //border-bottom: 1px solid #323233;
      margin-bottom: 60px;
    }
  }
}
.goods-list {
  padding: pxTo(12) pxTo(0) pxTo(0) pxTo(8);
  //display: flex;
  margin-bottom: pxTo(50);
  overflow-y: scroll;
  height: pxTo(600);
   background: #fff;
 //  width: 72%;
  > div {
    width: pxTo(110);

    &:nth-last-of-type(1) {
      margin-left: pxTo(11);
    }
  }
}
</style>